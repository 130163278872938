<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/unit" class="main_left" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>

  <!-- 入住办理功能 -->
  <vxe-modal ref="xModal" width="800" title="入住办理" show-zoom>
    <template v-slot>
      <vxe-form ref="xForm" v-bind="stayInOptions" v-loading="loading" @submit="submitEvent" @reset="closeModal"></vxe-form>
    </template>
  </vxe-modal>
</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      selectRow: null,
      currNode: {},
      parentNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  if (this.currNode.type == '1003') {
                    let { data } = this.formOptions;
                    data["parentId"] = this.currNode.id;
                    data["communityId"] = this.currNode.communityId;
                    data["floorId"] = this.parentNode.id;
                    data["unitId"] = this.currNode.id;
                    this.$refs.xGrid.insertEvent();
                  } else {
                    this.$message.error('请选择单元')
                  }
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "name",
            title: "房屋编号",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入房屋编号",
              },
            },
          },
          {
            field: "roomType",
            title: "房屋类型",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "houseType",
              props: {
                placeholder: "请选择房屋类型",
              },
            },
          },
          {
            field: "section",
            title: "房屋户型",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "section",
              props: {
                placeholder: "请选择房屋户型",
              },
            },
          },
          {
            field: "layer",
            title: "所属楼层",
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                type: "number",
                min: 1,
                max: 34,
                maxlength: 24,
                placeholder: "请输入所属楼层",
              },
            },
          },
          {
            field: "roomBuiltUpArea",
            title: "建筑面积",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入建筑面积",
              },
            },
          },
          {
            field: "feeCoefficient",
            title: "算费系数",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                maxlength: 24,
                placeholder: "请输入算费系数",
              }
            },
          },
          {
            field: "ownerName",
            title: this.$t("currentOwner"),
            minWidth: 120,
            editRender: {
              enabled: false,
              name: "$input",
              props: {
                placeholder: this.$t("currentPlaceholder"),
              }
            },
          },
          {
            field: "ownerLink",
            title: "联系电话",
            minWidth: 120,
            editRender: {
              enabled: false,
              name: "$input",
              props: {
                placeholder: "请输入联系电话",
              }
            },
          },
          {
            field: "status",
            title: "房屋状态",
            width: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "houseStatus",
              props: {
                placeholder: "请选择房屋状态",
              }
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 200,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  status: "primary",
                  label: "入住办理",
                  name: 'stayIn',
                  events: {
                    click: (row) => {
                      this.queryEvent(row)
                    }
                  }
                },
                {
                  status: "primary",
                  label: "修改",
                  name: 'edit',
                  eventName: "queryEvent"
                },
                {
                  status: "danger",
                  label: "删除",
                  name: 'delete',
                  eventName: "removeEvent"
                }
              ]
            },
          },
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "parentId",
            title: "所属上级",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属上级",
              },
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '1003') {
                    data["communityId"] = node.communityId;
                    data["parentId"] = node.id;
                  } else {
                    this.$message.error('请选择单元')
                  }
                },
              }
            },
          },
          {
            field: "code",
            title: "房间编号",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入房间编号(如:0101)",
              },
              events: {
                blur: ({ data, property }) => {
                  if (data[property].length < 4) {
                    data[property] = `0${data[property]}`
                  }
                  if (data.id) {
                    data["name"] = `${data.buildingCode}-${data.unitCode}-${data[property]}`
                  } else {
                    data["name"] = `${this.parentNode.code}-${this.currNode.code}-${data[property]}`
                  }
                },
                change: ({ data, property }) => {
                  if (data.id) {
                    data["name"] = `${data.buildingCode}-${data.unitCode}-${data[property]}`
                  } else {
                    data["name"] = `${this.parentNode.code}-${this.currNode.code}-${data[property]}`
                  }
                }
              }
            },
          },
          {
            field: "name",
            title: "房屋编号",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入房屋编号(自动生成)",
              },
            },
          },
          {
            field: "layer",
            title: "所属楼层",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "number",
                min: -3,
                max: 34,
                maxlength: 24,
                placeholder: "请输入所属楼层",
              },
            },
          },
          {
            field: "roomType",
            title: "房屋类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "houseType",
              props: {
                placeholder: "请选择房屋类型",
              },
            },
          },
          {
            field: "section",
            title: "户型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "section",
              props: {
                placeholder: "请选择户型",
              },
            },
          },
          {
            field: "feeCoefficient",
            title: "算费系数",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 1,
                placeholder: "请输入算费系数",
              },
            },
          },
          {
            field: "roomBuiltUpArea",
            title: "建筑面积",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入建筑面积",
              },
            },
          },
          {
            field: "roomUseArea",
            title: "使用面积",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0.00,
                maxlength: 9,
                placeholder: "请输入使用面积（米²）",
              },
            },
          },
          {
            field: "rentalArea",
            title: "计租面积",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0.00,
                maxlength: 9,
                placeholder: "请输入计租面积（米²）",
              },
            },
          },
          {
            field: "ownershipType",
            title: "权属类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "ownershipType",
              props: {
                placeholder: "请选择权属类型",
              },
            },
          },
          {
            field: "toward",
            title: "朝向",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "toward",
              props: {
                placeholder: "请选择户型",
              },
            },
          },
          {
            field: "renovation",
            title: "是否装修",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "isIt",
              props: {
                placeholder: "请选择是否装修",
              },
            },
          },
          // {
          //   field: "billingArea",
          //   title: "计费面积",
          //   span: 12,
          //   itemRender: {
          //     name: "$input",
          //     props: {
          //       type: "float",
          //       min: 0.00,
          //       
          //       maxlength: 9,
          //       placeholder: "请输入计费面积（米²）",
          //     },
          //   },
          // },
          // {
          //   field: 'remark',
          //   title: '描述',
          //   span: 24,
          //   itemRender: {
          //     name: '$textarea',
          //     props: {
          //       showWordCount: true,
          //       maxlength: "300",
          //       resize: "none",
          //       placeholder: '请输入描述内容'
          //     }
          //   }
          // },

          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属公司" }],
          code: [
            { required: true, message: "请输入房间编号(如:0101)" },
            {
              validator({ itemValue }) {
                if (itemValue && itemValue.length < 3) {
                  return new Error('请输入房间编号(如:0101)');
                }
              },
              trigger: 'change'
            }
          ],
          name: [{ required: true, message: "请输入房屋编号" }],
          roomType: [{ required: true, message: "请选择房屋类型" }],
          layer: [{ required: true, message: "请输入所属楼层" }],
          section: [{ required: true, message: "请选择房型" }],
          roomBuiltUpArea: [{ required: true, message: "请输入建筑面积" }],
          feeCoefficient: [{ required: true, message: "请输入算费系数" }],
          status: [{ required: true, message: "请选择房屋状态" }],
        },
        // 新增功能字段初始化
        data: {
          code: "",
          name: "",
          section: "2",
          ownershipType: "",
          toward: "",
          enabled: "1",
          roomType: "",
          type: "1004",
          feeCoefficient: "1",
          communityId: '',
          ownerId: "",
          status: "1",
          renovation: "",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "房屋编号",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入房屋编号",
              },
            },
          },
          {
            field: "roomType",
            title: "房屋类型",
            itemRender: {
              name: "$select",
              optionTypeCode: "houseType",
              props: {
                maxlength: 24,
                placeholder: "请选择房屋类型",
              },
            },
          },
          {
            field: "section",
            title: "户型",
            folding: true,
            itemRender: {
              name: "$select",
              optionTypeCode: "section",
              props: {
                maxlength: 24,
                placeholder: "请选择户型",
              },
            },
          },
          {
            align: "center",
            collapseNode: true,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          name: '',
          roomType: "",
          section: "",
          status: "",
        },
      },

      stayInOptions: {
        // 新增功能字段配置
        width: 800,
        height: 600,
        titleWidth: 100,
        items: [{
            field: "ownerName",
            title: this.$t("currentOwner"),
            span: 12,
            itemRender: {
              name: "$VxeSelectOwner",
              defaultProps: {
                label: "name",
                value: "id",
              },
              props: {
                suffixIcon: "fa fa-search",
                placeholder: this.$t("currentPlaceholder"),
              },
              events: {
                change: (params, row) => {
                  let { data, property } = params;
                  data[property] = row.name;
                  data["ownerId"] = row.id;
                  data["ownerLink"] = row.phone;
                  data["status"] = row.id ? "3" : "1";
                  data["checkInDate"] = data["ownerId"] ? this.$XEUtils.toDateString(new Date(), "yyyy-MM-dd HH:mm:ss") : "";
                },
              }
            },
          },
          {
            field: "ownerLink",
            title: "联系方式",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入联系方式",
              },
            },
          },
          {
            field: "status",
            title: "房屋状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "houseStatus",
              props: {
                placeholder: "请选择房屋状态",
              },
            },
          },
          {
            field: "checkInDate",
            title: "入住日期",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择入住日期",
              },
            },
          },
          {
            field: 'remark',
            title: '描述',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入描述内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          ownerName: [{ required: true, message: this.$t("currentPlaceholder") }],
          ownerLink: [{ required: true, message: "请输入联系方式" }],
          status: [{ required: true, message: "请选择房屋状态" }],
          checkInDate: [{ required: true, message: "请选择入住日期" }],
        },
        // 新增功能字段初始化
        data: {
          ownerId: "",
          status: "",
          checkInDate: "",
        },
      },
    };
  },

  methods: {
    ...mapActions([
      "queryEventService",
      "submitEventService",
    ]),
    // 获取数据渲染表格
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.parentNode = this.$Tools.getNodeById(treeData, node.parentId);
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      let { data } = this.searchOptions;
      data["parentType"] = node.type;
      data["parentId"] = node.id;
      this.$refs.xGrid.getLists();
    },

    // 查询房屋信息
    queryEvent(row) {
      this.loading = true;
      this.queryEventService({
        service: this.$route.meta.service,
        params: row,
        dictTypeCodes: ["houseStatus"]
      }).then(({ code, data, dicts }) => {
        if (code == 200) {
          this.stayInOptions.data = data;
          this.$Tools.buildDataByDicts(this.stayInOptions.items, dicts, { value: "code", label: "name" });
          this.$refs.xModal.open();
        }
      });
    },

    // 入住办理
    submitEvent({ data }) {
      this.submitEventService({
        service: this.$route.meta.service,
        params: data,
      }).then(({ code }) => {
        if (code == 200) {
          this.$refs.xGrid.getLists();
          this.$message.success(data.id ? "更新成功" : "保存成功");
          this.closeModal();
        }
        this.resetEvent();
      }).catch((err) => {
        this.resetEvent();
      });
    },
    resetEvent() {
      this.loading = false;
    },
    closeModal() {
      this.$refs.xModal.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
